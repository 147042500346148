<template>
  <div
    v-if="employees"
    class="CreateClient"
  >
    <div class="CreateClient__header row row--justify-between">
      <label class="fs-22 fw-med">New Client</label>
      <base-button
        v-if="!prod"
        @click="hydrateFakeClientForTesting"
        class="bg-blue fc-white"
      >
        SHAZAM!!!
      </base-button>
    </div>

    <hr class="Divider">

    <div class="CreateClient__section-company column">
      <label class="fs-16 fw-med mb-15">Company</label>
      <div class="row row--wrap">
        <base-input
          v-model.trim="businessName.value"
          class="CreateClient__input--medium mr-8 mb-25"
          :instructions="instructions.LEGAL_NAME_INSTRUCTION"
          :label="true"
          :label-for="'name'"
          :valid="businessName.valid"
        >
          Business name
        </base-input>

        <div class="column column--width-auto mb-25">
          <label
            class="BaseInput__label uppercase"
            for="corporation-type"
          >
            {{ corporationType.valid ? 'Corporation Type' : 'Select a corporation type' }}
          </label>
          <v-select
            v-model="corporationType.value"
            aria-label="Select input to choose corporation type option"
            id="corporation-type"
            :class="['CreateClient__select mr-9', {
              'CreateClient__select--invalid': !corporationType.valid
            }]"
            :clearable="false"
            :options="corporationType.options"
          />
        </div>

        <base-input
          v-model.trim="email.value"
          class="CreateClient__input--short mr-9 mb-25"
          :instructions="
            email.unique
              ? instructions.EMAIL_INSTRUCTION
              : instructions.EMAIL_UNIQUE_INSTRUCTION
          "
          :label="true"
          :label-for="'email'"
          :valid="email.valid && email.unique"
        >
          Email
        </base-input>

        <base-input
          v-model.trim="shortenedName.value"
          class="CreateClient__input--short mr-9 mb-25"
          :instructions="instructions.SHORTENED_NAME_INSTRUCTION"
          :label="true"
          :label-for="'shortened_name'"
          :valid="shortenedName.valid"
        >
          Shortened Name
        </base-input>

        <base-input
          v-model.trim="dba.value"
          class="CreateClient__input--short mr-9 mb-25"
          :instructions="instructions.DBA_CLIENT_INSTRUCTION"
          :label="true"
          :label-for="'dba'"
          :valid="dba.valid"
        >
          DBA
        </base-input>

        <base-input
          v-model.trim="ein.value"
          class="CreateClient__input--short mr-6 mb-25"
          :instructions="
            ein.unique
              ? instructions.EIN_INSTRUCTION
              : instructions.EIN_UNIQUE_INSTRUCTION
          "
          :label="true"
          :label-for="'ein'"
          :valid="ein.valid && ein.unique"
        >
          EIN
        </base-input>

        <!-- ADDRESS -->
        <base-input
          v-model.trim="address.value"
          class="CreateClient__input--long mr-9 mb-25"
          :instructions="instructions.ADDRESS_INSTRUCTION"
          :label="true"
          :label-for="'address'"
          :valid="address.valid"
        >
          Address
        </base-input>
        <base-input
          v-model.trim="address_2.value"
          class="CreateClient__input--short mr-9 mb-25"
          :instructions="instructions.ADDRESS_INSTRUCTION"
          :label="true"
          :label-for="'address_2'"
          :valid="address_2.valid"
        >
          Address 2
        </base-input>
        <base-input
          v-model.trim="city.value"
          class="CreateClient__input--short mr-9 mb-25"
          :instructions="instructions.CITY_INSTRUCTION"
          :label="true"
          :label-for="'city'"
          :valid="city.valid"
        >
          City
        </base-input>
        <div class="column column--width-auto mb-12">
          <label
            class="BaseInput__label uppercase"
            for="state"
          >
            State
          </label>
          <v-select
            v-model="state.value"
            aria-label="Select input to choose state"
            id="state"
            :class="['CreateClient__select mr-9', {
              'CreateClient__select--invalid': !state.valid
            }]"
            :clearable="false"
            :instructions="instructions.STATE_INSTRUCTION"
            :options="state.options"
            :placeholder="'State:'"
          />
        </div>
        <base-input
          v-model.trim="zip.value"
          class="CreateClient__input--short mr-9 mb-25"
          :instructions="instructions.ZIP_INSTRUCTION"
          :label="true"
          :label-for="'zip'"
          :valid="zip.valid"
        >
          Zip
        </base-input>

        <base-input
          v-model.trim="phone.value"
          class="CreateClient__input--short mr-9 mb-25"
          :instructions="
            phone.connected
              ? instructions.PHONE_INSTRUCTION
              : instructions.PHONE_CONNECTED_INSTRUCTION
          "
          :label="true"
          :label-for="'phone'"
          :valid="phone.valid && phone.connected"
        >
          Phone Number
        </base-input>
      </div>
    </div>

    <div class="CreateClient__section-account column">
      <label class="fs-16 fw-med mb-15">Account</label>
      <div class="row row--wrap">
        <base-input
          v-model.trim="factorRate.value"
          class="CreateClient__input--short mr-9 mb-25"
          :instructions="instructions.FACTOR_RATE_PERCENTAGE_INSTRUCTION"
          :label="true"
          :label-for="'factor-rate'"
          :valid="factorRate.valid"
        >
          Factor Rate
        </base-input>

        <div class="column column--width-auto mb-25">
          <label
            class="BaseInput__label uppercase"
            for="languages"
          >
            {{ languages.valid ? 'Languages' : 'Select valid language(s)' }}
          </label>
          <v-select
            v-model="languages.value"
            aria-label="Select input to choose language option(s)"
            id="languages"
            multiple
            :class="['CreateClient__select--language mr-9', {
              'CreateClient__select--invalid': !languages.valid
            }]"
            :clearable="false"
            :options="languages.options"
          />
        </div>

        <div class="column column--width-auto mb-25">
          <label
            class="BaseInput__label uppercase"
            for="lead-attribution"
          >
            {{ leadAttribution.valid ? 'Lead Attribution' : 'Select a lead attribution' }}
          </label>
          <v-select
            v-model="leadAttribution.value"
            aria-label="Select input to choose lead attribution option"
            id="lead-attribution"
            :class="['CreateClient__select mr-9', {
              'CreateClient__select--invalid': !leadAttribution.valid
            }]"
            :clearable="false"
            :options="leadAttribution.options"
          />
        </div>

        <div class="column column--width-auto mb-25">
          <label
            class="BaseInput__label uppercase"
            for="account-manager"
          >
            {{
              accountManagerId.valid
                ? 'Account Manager'
                : instructions.ACCOUNT_MANAGER_ID_INSTRUCTION
            }}
          </label>
          <v-select
            @input="setAccountManagerID"
            aria-label="Select input to choose the account manager"
            id="account-manager"
            :class="['CreateClient__select CreateClient__select--account-manager mr-9', {
              'CreateClient__select--invalid': !accountManagerId.valid
            }]"
            :clearable="false"
            :get-option-label="employee => `${employee.first_name} ${employee.last_name}`"
            :options="employees"
          />
        </div>

        <div class="column column--width-auto mb-25">
          <label
            class="BaseInput__label uppercase"
            for="sales-rep"
          >
            {{
              salesRepId.valid
                ? 'Sales Rep'
                : instructions.SALES_REP_ID_INSTRUCTION
            }}
          </label>
          <v-select
            @input="setSalesRepID"
            aria-label="Select input to choose the sales rep"
            id="sales-rep"
            :class="['CreateClient__select CreateClient__select--account-manager mr-9', {
              'CreateClient__select--invalid': !salesRepId.valid
            }]"
            :get-option-label="employee => `${employee.first_name} ${employee.last_name}`"
            :options="employees"
          />
        </div>

        <div class="column column--width-auto">
          <label
            class="fc-light fs-12 mb-7 uppercase"
            for="insurance-renewal-date"
          >
            {{
              insuranceRenewalDate.valid
                ? 'Insurance Renewal Date'
                : `Insurance Renewal: ${instructions.INSURANCE_RENEWAL_DATE_INSTRUCTION}`
            }}
          </label>
          <base-date-picker
            v-model="insuranceRenewalDate.value"
            class="mr-6 mb-25"
            id="insurance-renewal-date"
            :valid="insuranceRenewalDate.valid"
          />
        </div>
      </div>
    </div>

    <div class="CreateClient__section-authority column">
      <label class="fs-16 fw-med mb-15">Authority</label>
      <div class="row row--wrap">
        <base-input
          v-model.trim="mc.value"
          class="CreateClient__input--short mr-8 mb-25"
          :instructions="
            mc.unique
              ? instructions.MC_INSTRUCTION
              : instructions.MC_UNIQUE_INSTRUCTION
          "
          :label="true"
          :label-for="'mc-number'"
          :valid="mc.valid && mc.unique"
        >
          MC
        </base-input>

        <base-input
          v-model.trim="dot.value"
          class="CreateClient__input--short mr-9 mb-25"
          :instructions="
            dot.unique
              ? instructions.DOT_INSTRUCTION
              : instructions.DOT_UNIQUE_INSTRUCTION
          "
          :label="true"
          :label-for="'dot-number'"
          :valid="dot.valid && dot.unique"
        >
          DOT
        </base-input>

        <div class="column column--width-auto">
          <label
            class="fc-light fs-12 mb-7 uppercase"
            for="authority-date"
          >
            {{ authorityDate.valid ? 'Authority Date' : instructions.AUTHORITY_DATE_INSTRUCTION }}
          </label>
          <base-date-picker
            v-model="authorityDate.value"
            class="mr-6 mb-25"
            id="authority-date"
            :valid="authorityDate.valid"
          />
        </div>
      </div>
    </div>

    <hr class="Divider">

    <div class="CreateClient__save-wrap column column--align-center">
      <base-button
        @click="submitClient"
        class="CreateClient__save-button bg-blue fc-white"
        id="Testing__CreateClientSubmitBtn"
        :disabled="buttonDisabled || !isValid"
      >
        Save Client
      </base-button>
    </div>
  </div>
</template>

<script>
// Packages
import moment from 'moment'
// Helpers
import {
  Client,
  Employee
} from '../../../utils/api'
import displayErrorMessage from '../../../utils/errorMessages'
import { statesAbbreviations } from '../../../utils/constants'
import {
  formatPhoneNumber,
  formatStringCamelCase
} from '../../../utils/helpers'
// Components
import BaseButton from '../../../components/base-button.vue'
import BaseDatePicker from '../../../components/base-date-picker.vue'
import BaseInput from '../../../components/base-input.vue'
// Mixins
import {
  ValidationMixin
} from '../../../utils/validation-mixin'


export default {
  name: 'CreateClient',

  components: {
    BaseButton,
    BaseDatePicker,
    BaseInput,
  },

  mixins: [ValidationMixin],

  async created () {
    await this.loadEmployees()
  },

  data () {
    /*
     * Required fields:
     * accountManagerId, address, authorityDate, businessName, city, corporationType, dot,
     * ein, email, factorRate, insuranceRenewalDate, languages, leadAttribution, phone,
     * shortenedName, state, zip
    */
    return {
      accountManagerId: {
        valid: false,
        value: null,
      },
      address: {
        valid: false,
        value: '',
      },
      address_2: {
        valid: true,
        value: '',
      },
      authorityDate: {
        valid: false,
        value: null,
      },
      businessName: {
        valid: false,
        value: '',
      },
      buttonDisabled: false,
      city: {
        valid: false,
        value: '',
      },
      corporationType: {
        options: ['LLC', 'Corp', 'Sole Proprietor', 'Partnership'],
        valid: false,
        value: '',
      },
      dba: {
        valid: true,
        value: '',
      },
      dot: {
        unique: true,
        valid: false,
        value: '',
      },
      ein: {
        unique: true,
        valid: false,
        value: '',
      },
      email: {
        unique: true,
        valid: false,
        value: '',
      },
      employees: null,
      factorRate: {
        valid: false,
        value: '',
      },
      insuranceRenewalDate: {
        valid: false,
        value: null,
      },
      languages: {
        options: ['English', 'Punjabi', 'Spanish'],
        valid: false,
        value: []
      },
      leadAttribution: {
        options: [
          'Referral',
          'Web',
          'Email',
          'Cold Calling'
        ],
        valid: false,
        value: '',
      },
      mc: {
        unique: true,
        valid: false,
        value: '',
      },
      phone: {
        connected: true,
        valid: false,
        value: '',
      },
      phoneData: null,
      prod: process.env.VUE_APP_ENV === 'prod' || process.env.VUE_APP_ENV === 'prodlocal',
      salesRepId: {
        valid: true,
        value: null,
      },
      shortenedName: {
        valid: false,
        value: '',
      },
      state: {
        options: statesAbbreviations,
        valid: false,
        value: '',
      },
      zip: {
        valid: false,
        value: '',
      }
    }
  },

  computed: {
    isValid () {
      let valid = true

      if (!this.businessName.value || !this.isValidBusinessName(this.businessName.value)) {
        this.$set(this.businessName, 'valid', false)
        valid = false
      } else {
        this.$set(this.businessName, 'valid', true)
      }

      if (
        !this.corporationType.value
        || !this.isValidCorpType(this.corporationType.value.toLowerCase())
      ) {
        this.$set(this.corporationType, 'valid', false)
        valid = false
      } else {
        this.$set(this.corporationType, 'valid', true)
      }

      if (!this.email.value || !this.isValidEmail(this.email.value)) {
        this.$set(this.email, 'valid', false)
        valid = false
      } else {
        this.$set(this.email, 'valid', true)
      }

      if (
        !this.shortenedName.value
        || !this.isValidShortenedName(this.shortenedName.value)
      ) {
        this.$set(this.shortenedName, 'valid', false)
        valid = false
      } else {
        this.$set(this.shortenedName, 'valid', true)
      }

      // not required
      if (this.dba.value && !this.isValidDBAClient(this.dba.value)) {
        this.$set(this.dba, 'valid', false)
        valid = false
      } else {
        this.$set(this.dba, 'valid', true)
      }

      // not required
      if (this.ein.value && !this.isValidEIN(this.ein.value)) {
        this.$set(this.ein, 'valid', false)
        valid = false
      } else {
        this.$set(this.ein, 'valid', true)
      }

      if (!this.address.value || !this.isValidAddress(this.address.value)) {
        this.$set(this.address, 'valid', false)
        valid = false
      } else {
        this.$set(this.address, 'valid', true)
      }

      if (this.address_2.value && !this.isValidAddress(this.address_2.value)) {
        this.$set(this.address_2, 'valid', false)
        valid = false
      } else {
        this.$set(this.address_2, 'valid', true)
      }

      if (!this.city.value || !this.isValidCity(this.city.value)) {
        this.$set(this.city, 'valid', false)
        valid = false
      } else {
        this.$set(this.city, 'valid', true)
      }

      if (!this.state.value || !this.isValidState(this.state.value)) {
        this.$set(this.state, 'valid', false)
        valid = false
      } else {
        this.$set(this.state, 'valid', true)
      }

      if (!this.zip.value || !this.isValidZip(this.zip.value)) {
        this.$set(this.zip, 'valid', false)
        valid = false
      } else {
        this.$set(this.zip, 'valid', true)
      }

      if (!this.phone.value || !this.isValidPhone(this.phone.value)) {
        this.$set(this.phone, 'valid', false)
        valid = false
      } else {
        this.$set(this.phone, 'valid', true)
      }

      if (!this.isValidFactorRate(this.factorRate.value)) {
        this.$set(this.factorRate, 'valid', false)
        valid = false
      } else {
        this.$set(this.factorRate, 'valid', true)
      }

      if (
        !this.languages.value
        || !this.isValidLanguage(this.languages.value.map(lang => lang.trim().toLowerCase()))
      ) {
        this.$set(this.languages, 'valid', false)
        valid = false
      } else {
        this.$set(this.languages, 'valid', true)
      }

      if (
        !this.leadAttribution.value
        || !this.isValidLeadAttribution(this.leadAttribution.value.toLowerCase())
      ) {
        this.$set(this.leadAttribution, 'valid', false)
        valid = false
      } else {
        this.$set(this.leadAttribution, 'valid', true)
      }

      if (!this.accountManagerId.value) {
        this.$set(this.accountManagerId, 'valid', false)
        valid = false
      } else {
        this.$set(this.accountManagerId, 'valid', true)
      }

      // not required
      if (
        this.insuranceRenewalDate.value
        && !this.isValidInsuranceRenewalDate(this.insuranceRenewalDate.value)
      ) {
        this.$set(this.insuranceRenewalDate, 'valid', false)
        valid = false
      } else {
        this.$set(this.insuranceRenewalDate, 'valid', true)
      }

      // authority checks
      // If MC is present, check for validity
      if (this.mc.value && !this.isValidMC(this.mc.value)) {
        this.$set(this.mc, 'valid', false)
        valid = false
      } else {
        this.$set(this.mc, 'valid', true)
      }

      if (!this.dot.value || !this.isValidDOT(this.dot.value)) {
        this.$set(this.dot, 'valid', false)
        valid = false
      } else {
        this.$set(this.dot, 'valid', true)
      }

      // not required
      if (this.authorityDate.value && !this.isValidAuthorityDate(this.authorityDate.value)) {
        this.$set(this.authorityDate, 'valid', false)
        valid = false
      } else {
        this.$set(this.authorityDate, 'valid', true)
      }

      return valid
    }
  },

  methods: {
    hydrateFakeClientForTesting () {
      if (this.prod) return
      this.$set(this.address, 'value', '1800 Im a badass robot')
      this.$set(this.businessName, 'value', 'Tony Stark\'s Fun House of Robots')
      this.$set(this.city, 'value', 'Gothom')
      this.$set(this.corporationType, 'value', 'LLC')
      this.$set(this.dot, 'value', '41234')
      this.$set(this.email, 'value', 'tonyfuckingawesome@stark.com')
      this.$set(this.factorRate, 'value', '3')
      this.$set(this.languages, 'value', ['English'])
      this.$set(this.leadAttribution, 'value', 'Referral')
      this.$set(this.phone, 'value', '7203554177')
      this.$set(this.shortenedName, 'value', 'Iron Man')
      this.$set(this.state, 'value', 'CA')
      this.$set(this.zip, 'value', '90210')
    },

    async isUniqueAndConnected () {
      let valid = true

      this.progressStart()
      // Check for unique values
      if (!(await this.unique())) valid = false
      // ONLY check phone numbers for being connected if everything else is good
      this.phoneData = await this.checkPhoneNumber(this.phone.value)
      // this.phoneData will be `false` if `this.phone.value` is not connected
      if (this.phoneData) this.$set(this.phoneData, 'phone', formatPhoneNumber(this.phone.value))
      if (!this.phoneData.phone_valid) {
        valid = false
        this.$set(this.phone, 'connected', false)
        this.progressFail()
      } else {
        this.$set(this.phone, 'connected', true)
      }
      this.progressFinish()

      return valid
    },

    async loadEmployees () {
      this.progressStart()
      try {
        this.employees = (await Employee.getEmployees({}, 100)).data.rows
        this.progressFinish()
      }
      catch (error) {
        this.captureSentryEvent(
          'Create Client "Load Employees"',
          {
            config: error.config,
            data: this.$data,
            details: error,
            props: this.$props,
            response: error.response,
          }
        )
        this.CError(error)
        this.requestFailure({ message: 'There was an issue getting the available account managers' })
      }
    },

    setAccountManagerID (employee) {
      if (!employee) {
        this.$set(this.accountManagerId, 'valid', false)
        this.$set(this.accountManagerId, 'value', null)
        return
      }
      this.$set(this.accountManagerId, 'valid', true)
      this.$set(this.accountManagerId, 'value', employee.id)
    },

    setSalesRepID (employee) {
      if (!employee) {
        this.$set(this.salesRepId, 'valid', false)
        this.$set(this.salesRepId, 'value', null)
        return
      }
      this.$set(this.salesRepId, 'valid', true)
      this.$set(this.salesRepId, 'value', employee.id)
    },

    async submitClient () {
      this.progressStart()
      this.buttonDisabled = true
      // now check unique & see if phone is connected
      if (!this.isValid || !await this.isUniqueAndConnected()) {
        this.buttonDisabled = false
        return
      }

      // Remove all leading 0s from MC
      const doctoredMC = this.mc.value.replace(/^0+/, '')

      try {
        const newClient = (await Client.create({
          account_manager_id: this.accountManagerId.value,
          address: formatStringCamelCase(this.address.value),
          address_2: formatStringCamelCase(this.address_2.value),
          authority_date: moment(this.authorityDate.value),
          city: formatStringCamelCase(this.city.value),
          corporation_type: this.corporationType.value.toLowerCase(),
          doing_business_as: this.dba.value,
          dot: this.dot.value,
          // ein must be unique if not empty so needs to be null
          ein: this.ein.value !== ''
            ? this.ein.value
            : null,
          email: this.email.value.toLowerCase(),
          factor_rate_percentage: this.factorRate.value,
          insurance_renewal_date: this.insuranceRenewalDate.value,
          languages: this.languages.value.map(lang => lang.trim().toLowerCase()),
          lead_attribution: this.leadAttribution.value.toLowerCase(),
          mc: doctoredMC,
          // Do not alter input value
          // https://app.zenhub.com/workspaces/bobtail-5c64d9b0b66dba3bb32bda6e/issues/fs-bobtail/bobtail/1639
          name: this.businessName.value,
          ...this.phoneData,
          requires_verification: true,
          sales_rep_id: this.salesRepId.value,
          send_notifications: true,
          // Do not alter input value
          // https://app.zenhub.com/workspaces/bobtail-5c64d9b0b66dba3bb32bda6e/issues/fs-bobtail/bobtail/1639
          shortened_name: this.shortenedName.value,
          state: this.state.value,
          user: {
            email: this.email.value.toLowerCase()
          },
          zip: this.zip.value
        })).data

        // insert it into queue so it will show up immediately
        this.$store.commit('INSERT_USER_CREATE_QUEUE', {
          type: 'client',
          ...newClient
        })

        this.requestSuccess({ message: 'Client Successfully Created!' })
        this.$router.push({ name: 'user-management' })
      }
      catch (error) {
        this.captureSentryEvent(
          'Create Client "Submit"',
          {
            config: error.config,
            data: this.$data,
            details: error,
            props: this.$props,
            response: error.response,
          }
        )
        this.CError(error)
        let message = 'There was an issue creating a new client'
        if (error.response.data && error.response.data.message) {
          if (displayErrorMessage(error.response.data.message)) {
            message = `There was an error: ${error.response.data.message}`
          }
        }
        this.buttonDisabled = false
        this.requestFailure({ message })
      }
    },

    async unique () {
      let isUnique = true
      try {
        // CLIENT
        // ein, mc, dot, email must be unique
        const uniquePromises = [
          Client.uniqueField({ dot: this.dot.value }),
          Client.uniqueField({ useremail: this.email.value })
        ]

        // If MC is present, check for unique
        if (this.mc.value) uniquePromises.push(Client.uniqueField({ mc: this.mc.value }))
        // if EIN is present, check for unique
        if (this.ein.value) uniquePromises.push(Client.uniqueField({ ein: this.ein.value }))

        const uniqueResponses = (await Promise.all(uniquePromises))
          .map(response => response.data)

        if (!uniqueResponses[0]) { // DOT
          isUnique = false
          this.$set(this.dot, 'unique', false)
        } else {
          this.$set(this.dot, 'unique', true)
        }

        if (!uniqueResponses[1]) { // Email
          isUnique = false
          this.$set(this.email, 'unique', false)
        } else {
          this.$set(this.email, 'unique', true)
        }

        // If MC is present, it will always be the 2 index
        if (this.mc.value && !uniqueResponses[2]) { // MC
          isUnique = false
          this.$set(this.mc, 'unique', false)
        } else {
          this.$set(this.mc, 'unique', true)
        }

        // If EIN is present, it will be the last in the responses
        if (this.ein.value && !uniqueResponses[uniqueResponses.length - 1]) { // EIN
          isUnique = false
          this.$set(this.ein, 'unique', false)
        } else {
          this.$set(this.ein, 'unique', true)
        }

        return isUnique
      }
      catch (error) {
        this.captureSentryEvent(
          'Create Client "Unique Checks"',
          {
            config: error.config,
            data: this.$data,
            details: error,
            props: this.$props,
            response: error.response,
          }
        )
        // let the api error messages handle it
        this.CError(error)
        return false
      }
    }
  }
}
</script>

<style lang="sass">
.CreateClient
  background-color: $white
  border: $border
  border-radius: $border-radius
  box-shadow: $container-shadow
  margin-right: rem(50px)
  margin-top: rem(52px)
  padding: rem(44px) rem(47px) rem(21px) rem(29px)

  &__header
    margin-bottom: rem(41px)

  &__input

    &--long
      width: rem(417px)

    &--medium
      width: rem(230px)

    &--short
      width: rem(145px)

  &__save-button
    width: rem(326px)

  &__save-wrap
    margin-bottom: rem(100px)
    margin-top: rem(50px)

  &__section

    &-account
      margin-top: rem(31px)

    &-authority
      margin-bottom: rem(26px)
      margin-top: rem(31px)

    &-company
      margin-top: rem(41px)

  &__select
    width: rem(180px)

    &--account-manager
      width: rem(225px)

    &--invalid
      border: $border
      border-color: $danger
      border-radius: $border-radius

    &--language
      min-width: rem(180px)
      width: auto

</style>
