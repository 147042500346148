var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.employees
    ? _c("div", { staticClass: "CreateClient" }, [
        _c(
          "div",
          { staticClass: "CreateClient__header row row--justify-between" },
          [
            _c("label", { staticClass: "fs-22 fw-med" }, [
              _vm._v("New Client")
            ]),
            !_vm.prod
              ? _c(
                  "base-button",
                  {
                    staticClass: "bg-blue fc-white",
                    on: { click: _vm.hydrateFakeClientForTesting }
                  },
                  [_vm._v("\n      SHAZAM!!!\n    ")]
                )
              : _vm._e()
          ],
          1
        ),
        _c("hr", { staticClass: "Divider" }),
        _c("div", { staticClass: "CreateClient__section-company column" }, [
          _c("label", { staticClass: "fs-16 fw-med mb-15" }, [
            _vm._v("Company")
          ]),
          _c(
            "div",
            { staticClass: "row row--wrap" },
            [
              _c(
                "base-input",
                {
                  staticClass: "CreateClient__input--medium mr-8 mb-25",
                  attrs: {
                    instructions: _vm.instructions.LEGAL_NAME_INSTRUCTION,
                    label: true,
                    "label-for": "name",
                    valid: _vm.businessName.valid
                  },
                  model: {
                    value: _vm.businessName.value,
                    callback: function($$v) {
                      _vm.$set(
                        _vm.businessName,
                        "value",
                        typeof $$v === "string" ? $$v.trim() : $$v
                      )
                    },
                    expression: "businessName.value"
                  }
                },
                [_vm._v("\n        Business name\n      ")]
              ),
              _c(
                "div",
                { staticClass: "column column--width-auto mb-25" },
                [
                  _c(
                    "label",
                    {
                      staticClass: "BaseInput__label uppercase",
                      attrs: { for: "corporation-type" }
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(
                            _vm.corporationType.valid
                              ? "Corporation Type"
                              : "Select a corporation type"
                          ) +
                          "\n        "
                      )
                    ]
                  ),
                  _c("v-select", {
                    class: [
                      "CreateClient__select mr-9",
                      {
                        "CreateClient__select--invalid": !_vm.corporationType
                          .valid
                      }
                    ],
                    attrs: {
                      "aria-label":
                        "Select input to choose corporation type option",
                      id: "corporation-type",
                      clearable: false,
                      options: _vm.corporationType.options
                    },
                    model: {
                      value: _vm.corporationType.value,
                      callback: function($$v) {
                        _vm.$set(_vm.corporationType, "value", $$v)
                      },
                      expression: "corporationType.value"
                    }
                  })
                ],
                1
              ),
              _c(
                "base-input",
                {
                  staticClass: "CreateClient__input--short mr-9 mb-25",
                  attrs: {
                    instructions: _vm.email.unique
                      ? _vm.instructions.EMAIL_INSTRUCTION
                      : _vm.instructions.EMAIL_UNIQUE_INSTRUCTION,
                    label: true,
                    "label-for": "email",
                    valid: _vm.email.valid && _vm.email.unique
                  },
                  model: {
                    value: _vm.email.value,
                    callback: function($$v) {
                      _vm.$set(
                        _vm.email,
                        "value",
                        typeof $$v === "string" ? $$v.trim() : $$v
                      )
                    },
                    expression: "email.value"
                  }
                },
                [_vm._v("\n        Email\n      ")]
              ),
              _c(
                "base-input",
                {
                  staticClass: "CreateClient__input--short mr-9 mb-25",
                  attrs: {
                    instructions: _vm.instructions.SHORTENED_NAME_INSTRUCTION,
                    label: true,
                    "label-for": "shortened_name",
                    valid: _vm.shortenedName.valid
                  },
                  model: {
                    value: _vm.shortenedName.value,
                    callback: function($$v) {
                      _vm.$set(
                        _vm.shortenedName,
                        "value",
                        typeof $$v === "string" ? $$v.trim() : $$v
                      )
                    },
                    expression: "shortenedName.value"
                  }
                },
                [_vm._v("\n        Shortened Name\n      ")]
              ),
              _c(
                "base-input",
                {
                  staticClass: "CreateClient__input--short mr-9 mb-25",
                  attrs: {
                    instructions: _vm.instructions.DBA_CLIENT_INSTRUCTION,
                    label: true,
                    "label-for": "dba",
                    valid: _vm.dba.valid
                  },
                  model: {
                    value: _vm.dba.value,
                    callback: function($$v) {
                      _vm.$set(
                        _vm.dba,
                        "value",
                        typeof $$v === "string" ? $$v.trim() : $$v
                      )
                    },
                    expression: "dba.value"
                  }
                },
                [_vm._v("\n        DBA\n      ")]
              ),
              _c(
                "base-input",
                {
                  staticClass: "CreateClient__input--short mr-6 mb-25",
                  attrs: {
                    instructions: _vm.ein.unique
                      ? _vm.instructions.EIN_INSTRUCTION
                      : _vm.instructions.EIN_UNIQUE_INSTRUCTION,
                    label: true,
                    "label-for": "ein",
                    valid: _vm.ein.valid && _vm.ein.unique
                  },
                  model: {
                    value: _vm.ein.value,
                    callback: function($$v) {
                      _vm.$set(
                        _vm.ein,
                        "value",
                        typeof $$v === "string" ? $$v.trim() : $$v
                      )
                    },
                    expression: "ein.value"
                  }
                },
                [_vm._v("\n        EIN\n      ")]
              ),
              _c(
                "base-input",
                {
                  staticClass: "CreateClient__input--long mr-9 mb-25",
                  attrs: {
                    instructions: _vm.instructions.ADDRESS_INSTRUCTION,
                    label: true,
                    "label-for": "address",
                    valid: _vm.address.valid
                  },
                  model: {
                    value: _vm.address.value,
                    callback: function($$v) {
                      _vm.$set(
                        _vm.address,
                        "value",
                        typeof $$v === "string" ? $$v.trim() : $$v
                      )
                    },
                    expression: "address.value"
                  }
                },
                [_vm._v("\n        Address\n      ")]
              ),
              _c(
                "base-input",
                {
                  staticClass: "CreateClient__input--short mr-9 mb-25",
                  attrs: {
                    instructions: _vm.instructions.ADDRESS_INSTRUCTION,
                    label: true,
                    "label-for": "address_2",
                    valid: _vm.address_2.valid
                  },
                  model: {
                    value: _vm.address_2.value,
                    callback: function($$v) {
                      _vm.$set(
                        _vm.address_2,
                        "value",
                        typeof $$v === "string" ? $$v.trim() : $$v
                      )
                    },
                    expression: "address_2.value"
                  }
                },
                [_vm._v("\n        Address 2\n      ")]
              ),
              _c(
                "base-input",
                {
                  staticClass: "CreateClient__input--short mr-9 mb-25",
                  attrs: {
                    instructions: _vm.instructions.CITY_INSTRUCTION,
                    label: true,
                    "label-for": "city",
                    valid: _vm.city.valid
                  },
                  model: {
                    value: _vm.city.value,
                    callback: function($$v) {
                      _vm.$set(
                        _vm.city,
                        "value",
                        typeof $$v === "string" ? $$v.trim() : $$v
                      )
                    },
                    expression: "city.value"
                  }
                },
                [_vm._v("\n        City\n      ")]
              ),
              _c(
                "div",
                { staticClass: "column column--width-auto mb-12" },
                [
                  _c(
                    "label",
                    {
                      staticClass: "BaseInput__label uppercase",
                      attrs: { for: "state" }
                    },
                    [_vm._v("\n          State\n        ")]
                  ),
                  _c("v-select", {
                    class: [
                      "CreateClient__select mr-9",
                      {
                        "CreateClient__select--invalid": !_vm.state.valid
                      }
                    ],
                    attrs: {
                      "aria-label": "Select input to choose state",
                      id: "state",
                      clearable: false,
                      instructions: _vm.instructions.STATE_INSTRUCTION,
                      options: _vm.state.options,
                      placeholder: "State:"
                    },
                    model: {
                      value: _vm.state.value,
                      callback: function($$v) {
                        _vm.$set(_vm.state, "value", $$v)
                      },
                      expression: "state.value"
                    }
                  })
                ],
                1
              ),
              _c(
                "base-input",
                {
                  staticClass: "CreateClient__input--short mr-9 mb-25",
                  attrs: {
                    instructions: _vm.instructions.ZIP_INSTRUCTION,
                    label: true,
                    "label-for": "zip",
                    valid: _vm.zip.valid
                  },
                  model: {
                    value: _vm.zip.value,
                    callback: function($$v) {
                      _vm.$set(
                        _vm.zip,
                        "value",
                        typeof $$v === "string" ? $$v.trim() : $$v
                      )
                    },
                    expression: "zip.value"
                  }
                },
                [_vm._v("\n        Zip\n      ")]
              ),
              _c(
                "base-input",
                {
                  staticClass: "CreateClient__input--short mr-9 mb-25",
                  attrs: {
                    instructions: _vm.phone.connected
                      ? _vm.instructions.PHONE_INSTRUCTION
                      : _vm.instructions.PHONE_CONNECTED_INSTRUCTION,
                    label: true,
                    "label-for": "phone",
                    valid: _vm.phone.valid && _vm.phone.connected
                  },
                  model: {
                    value: _vm.phone.value,
                    callback: function($$v) {
                      _vm.$set(
                        _vm.phone,
                        "value",
                        typeof $$v === "string" ? $$v.trim() : $$v
                      )
                    },
                    expression: "phone.value"
                  }
                },
                [_vm._v("\n        Phone Number\n      ")]
              )
            ],
            1
          )
        ]),
        _c("div", { staticClass: "CreateClient__section-account column" }, [
          _c("label", { staticClass: "fs-16 fw-med mb-15" }, [
            _vm._v("Account")
          ]),
          _c(
            "div",
            { staticClass: "row row--wrap" },
            [
              _c(
                "base-input",
                {
                  staticClass: "CreateClient__input--short mr-9 mb-25",
                  attrs: {
                    instructions:
                      _vm.instructions.FACTOR_RATE_PERCENTAGE_INSTRUCTION,
                    label: true,
                    "label-for": "factor-rate",
                    valid: _vm.factorRate.valid
                  },
                  model: {
                    value: _vm.factorRate.value,
                    callback: function($$v) {
                      _vm.$set(
                        _vm.factorRate,
                        "value",
                        typeof $$v === "string" ? $$v.trim() : $$v
                      )
                    },
                    expression: "factorRate.value"
                  }
                },
                [_vm._v("\n        Factor Rate\n      ")]
              ),
              _c(
                "div",
                { staticClass: "column column--width-auto mb-25" },
                [
                  _c(
                    "label",
                    {
                      staticClass: "BaseInput__label uppercase",
                      attrs: { for: "languages" }
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(
                            _vm.languages.valid
                              ? "Languages"
                              : "Select valid language(s)"
                          ) +
                          "\n        "
                      )
                    ]
                  ),
                  _c("v-select", {
                    class: [
                      "CreateClient__select--language mr-9",
                      {
                        "CreateClient__select--invalid": !_vm.languages.valid
                      }
                    ],
                    attrs: {
                      "aria-label": "Select input to choose language option(s)",
                      id: "languages",
                      multiple: "",
                      clearable: false,
                      options: _vm.languages.options
                    },
                    model: {
                      value: _vm.languages.value,
                      callback: function($$v) {
                        _vm.$set(_vm.languages, "value", $$v)
                      },
                      expression: "languages.value"
                    }
                  })
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "column column--width-auto mb-25" },
                [
                  _c(
                    "label",
                    {
                      staticClass: "BaseInput__label uppercase",
                      attrs: { for: "lead-attribution" }
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(
                            _vm.leadAttribution.valid
                              ? "Lead Attribution"
                              : "Select a lead attribution"
                          ) +
                          "\n        "
                      )
                    ]
                  ),
                  _c("v-select", {
                    class: [
                      "CreateClient__select mr-9",
                      {
                        "CreateClient__select--invalid": !_vm.leadAttribution
                          .valid
                      }
                    ],
                    attrs: {
                      "aria-label":
                        "Select input to choose lead attribution option",
                      id: "lead-attribution",
                      clearable: false,
                      options: _vm.leadAttribution.options
                    },
                    model: {
                      value: _vm.leadAttribution.value,
                      callback: function($$v) {
                        _vm.$set(_vm.leadAttribution, "value", $$v)
                      },
                      expression: "leadAttribution.value"
                    }
                  })
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "column column--width-auto mb-25" },
                [
                  _c(
                    "label",
                    {
                      staticClass: "BaseInput__label uppercase",
                      attrs: { for: "account-manager" }
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(
                            _vm.accountManagerId.valid
                              ? "Account Manager"
                              : _vm.instructions.ACCOUNT_MANAGER_ID_INSTRUCTION
                          ) +
                          "\n        "
                      )
                    ]
                  ),
                  _c("v-select", {
                    class: [
                      "CreateClient__select CreateClient__select--account-manager mr-9",
                      {
                        "CreateClient__select--invalid": !_vm.accountManagerId
                          .valid
                      }
                    ],
                    attrs: {
                      "aria-label":
                        "Select input to choose the account manager",
                      id: "account-manager",
                      clearable: false,
                      "get-option-label": function(employee) {
                        return employee.first_name + " " + employee.last_name
                      },
                      options: _vm.employees
                    },
                    on: { input: _vm.setAccountManagerID }
                  })
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "column column--width-auto mb-25" },
                [
                  _c(
                    "label",
                    {
                      staticClass: "BaseInput__label uppercase",
                      attrs: { for: "sales-rep" }
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(
                            _vm.salesRepId.valid
                              ? "Sales Rep"
                              : _vm.instructions.SALES_REP_ID_INSTRUCTION
                          ) +
                          "\n        "
                      )
                    ]
                  ),
                  _c("v-select", {
                    class: [
                      "CreateClient__select CreateClient__select--account-manager mr-9",
                      {
                        "CreateClient__select--invalid": !_vm.salesRepId.valid
                      }
                    ],
                    attrs: {
                      "aria-label": "Select input to choose the sales rep",
                      id: "sales-rep",
                      "get-option-label": function(employee) {
                        return employee.first_name + " " + employee.last_name
                      },
                      options: _vm.employees
                    },
                    on: { input: _vm.setSalesRepID }
                  })
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "column column--width-auto" },
                [
                  _c(
                    "label",
                    {
                      staticClass: "fc-light fs-12 mb-7 uppercase",
                      attrs: { for: "insurance-renewal-date" }
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(
                            _vm.insuranceRenewalDate.valid
                              ? "Insurance Renewal Date"
                              : "Insurance Renewal: " +
                                  _vm.instructions
                                    .INSURANCE_RENEWAL_DATE_INSTRUCTION
                          ) +
                          "\n        "
                      )
                    ]
                  ),
                  _c("base-date-picker", {
                    staticClass: "mr-6 mb-25",
                    attrs: {
                      id: "insurance-renewal-date",
                      valid: _vm.insuranceRenewalDate.valid
                    },
                    model: {
                      value: _vm.insuranceRenewalDate.value,
                      callback: function($$v) {
                        _vm.$set(_vm.insuranceRenewalDate, "value", $$v)
                      },
                      expression: "insuranceRenewalDate.value"
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ]),
        _c("div", { staticClass: "CreateClient__section-authority column" }, [
          _c("label", { staticClass: "fs-16 fw-med mb-15" }, [
            _vm._v("Authority")
          ]),
          _c(
            "div",
            { staticClass: "row row--wrap" },
            [
              _c(
                "base-input",
                {
                  staticClass: "CreateClient__input--short mr-8 mb-25",
                  attrs: {
                    instructions: _vm.mc.unique
                      ? _vm.instructions.MC_INSTRUCTION
                      : _vm.instructions.MC_UNIQUE_INSTRUCTION,
                    label: true,
                    "label-for": "mc-number",
                    valid: _vm.mc.valid && _vm.mc.unique
                  },
                  model: {
                    value: _vm.mc.value,
                    callback: function($$v) {
                      _vm.$set(
                        _vm.mc,
                        "value",
                        typeof $$v === "string" ? $$v.trim() : $$v
                      )
                    },
                    expression: "mc.value"
                  }
                },
                [_vm._v("\n        MC\n      ")]
              ),
              _c(
                "base-input",
                {
                  staticClass: "CreateClient__input--short mr-9 mb-25",
                  attrs: {
                    instructions: _vm.dot.unique
                      ? _vm.instructions.DOT_INSTRUCTION
                      : _vm.instructions.DOT_UNIQUE_INSTRUCTION,
                    label: true,
                    "label-for": "dot-number",
                    valid: _vm.dot.valid && _vm.dot.unique
                  },
                  model: {
                    value: _vm.dot.value,
                    callback: function($$v) {
                      _vm.$set(
                        _vm.dot,
                        "value",
                        typeof $$v === "string" ? $$v.trim() : $$v
                      )
                    },
                    expression: "dot.value"
                  }
                },
                [_vm._v("\n        DOT\n      ")]
              ),
              _c(
                "div",
                { staticClass: "column column--width-auto" },
                [
                  _c(
                    "label",
                    {
                      staticClass: "fc-light fs-12 mb-7 uppercase",
                      attrs: { for: "authority-date" }
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(
                            _vm.authorityDate.valid
                              ? "Authority Date"
                              : _vm.instructions.AUTHORITY_DATE_INSTRUCTION
                          ) +
                          "\n        "
                      )
                    ]
                  ),
                  _c("base-date-picker", {
                    staticClass: "mr-6 mb-25",
                    attrs: {
                      id: "authority-date",
                      valid: _vm.authorityDate.valid
                    },
                    model: {
                      value: _vm.authorityDate.value,
                      callback: function($$v) {
                        _vm.$set(_vm.authorityDate, "value", $$v)
                      },
                      expression: "authorityDate.value"
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ]),
        _c("hr", { staticClass: "Divider" }),
        _c(
          "div",
          {
            staticClass: "CreateClient__save-wrap column column--align-center"
          },
          [
            _c(
              "base-button",
              {
                staticClass: "CreateClient__save-button bg-blue fc-white",
                attrs: {
                  id: "Testing__CreateClientSubmitBtn",
                  disabled: _vm.buttonDisabled || !_vm.isValid
                },
                on: { click: _vm.submitClient }
              },
              [_vm._v("\n      Save Client\n    ")]
            )
          ],
          1
        )
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }