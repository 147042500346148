// Some error messages from the server need to be displayed but others are unhelpful

const displayableErrors = [
  'cannot be null',
  'must be unique',
  'must have a length'
]

const displayErrorMessage = (message) => {
  return displayableErrors.some(error => message.includes(error))
}

export default displayErrorMessage